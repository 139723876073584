<template>
<div class="wrapper">
  <div class="products" v-if="products.length > 0">
    <div class="product" v-for="product in products" :key="product.id">
      <div class="info">
        <h1>{{product.name}}</h1>
        <p>{{product.country}}</p>
      </div>
      <div class="image">
        <img :src="'/images/products/'+product.image">
      </div>
      <div class="price">
        <h2>{{product.price}}</h2>
        <button @click="removeFromCart(product.id)" class="auto">Remove From Cart</button>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Sorry, no products in your cart right now</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'CartList',
  props: {
    products: Array,
  },
    methods: {
        removeFromCart(id) {
            this.$root.$data.cart = this.$root.$data.cart.filter(product => product.id != id);
        }
    }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.products {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product {
  margin: 10px;
  margin-top: 50px;
  width: 200px;
}

.product img {
  border: 2px solid #333;
  height: 250px;
  width: 200px;
  object-fit: cover;
}

.product .image {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.info {
  background: rgb(89, 255, 0);
  color: rgb(255, 4, 0);
  padding: 10px 30px;
  height: 80px;
}

.info h1 {
  font-size: 16px;
}

.info h2 {
  font-size: 14px;
}

.info p {
  margin: 0px;
  font-size: 10px;
}


.price {
  display: flex;
}

button {
  height: 50px;
  background: rgb(162, 39, 39);
  color: rgb(44, 142, 247);
  border: none;
}

.auto {
  margin-left: auto;
}
</style>